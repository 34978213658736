import anime from 'animejs/lib/anime.es.js';

export const initShowMoreContent = () => {
    // VARS
    const buttons = document.querySelectorAll('[data-showmore-content-button]');
    const duration = 300;
    const classVisible = 'is-visible';
    if (!buttons.length) return;

    // LISTENERS
    [...buttons].forEach((button) => {
        button.addEventListener('click', handleOnClickButton, false);
    });

    // HANDLERS
    function handleOnClickButton() {
        showMoreContent(this);
    }

    // FUNCTIONS
    function showMoreContent(button) {
        const content = button.closest('[data-showmore-content-box]').querySelector('[data-showmore-content]');
        const height = content.scrollHeight;

        button.style.display = 'none';

        anime({
            targets: content,
            height: [0, height],
            easing: 'linear',
            duration: duration,
            complete: function () {
                content.style.height = 'auto';
                content.classList.add(classVisible);
            },
        });
    }
};
